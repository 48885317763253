<template>

    <div>
        <div class="search_filter_wrap">
            <div class="search_item_wrap ma0">
                <div class="filter_category_box">
                    <el-select id="category" v-model="searchInputOption" class="my_filter select"
                               popper-class="category_select"
                               :popper-append-to-body="false"
                    >
                        <el-option :value="'ina'" :label="$t('subject')"></el-option>
                        <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                        <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
                        <el-option :value="'prni'" :label="$t('owner')"></el-option>
                        <el-option :value="'cuni'" :label="$t('curator')"></el-option>
                    </el-select>
                </div>
                <div class="search_input">
                    <el-input :placeholder="$t('st')" v-model="searchInput"
                              @input="setData('searchInput',searchInput)"
                              @keypress.enter="setFilterFunc(searchInputOption,searchInput)"/>
                    <button class="search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
                        <span>{{ $t('search') }}</span>
                        <img src="@/assets/image/main/search_btn.png"></button>
                </div>
                <div class="end_filter">
                    <el-popover
                        v-model="showPopover"
                        placement=""
                        width="435"
                        trigger="click"
                        :open-delay="300"
                        :offset="0"
                        :append-to-body="false"
                        :popper-options="{
                      boundariesElement: 'body',
                      gpuAcceleration: true,
                      positionFixed: true,
                      preventOverflow: true,
                    }">
                        <el-button slot="reference" class="filter_btn two"
                                   @click="changeCheck('filterEndCheck')"></el-button>
                        <div class="popup_tail"></div>
                        <div class="popup_bg">
                            <div class="popup_inner">
                                <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                                <div class="inner_middle">
                                    <div class="sub_title">{{ $t('category') }}</div>
                                    <div class="status_wrap">
                                        <el-checkbox-group v-model="filterCategory"
                                                           @change="setFilterFunc('ic', filterCategory)">
                                            <el-checkbox :label="2">{{ $t('art') }}</el-checkbox>
                                            <el-checkbox :label="178">{{ $t('prompt') }}</el-checkbox>
                                            <el-checkbox :label="9">{{ $t('collections') }}</el-checkbox>
                                            <el-checkbox :label="3">{{ $t('picture') }}</el-checkbox>
                                            <el-checkbox :label="5">{{ $t('music') }}</el-checkbox>
<!--                                            <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
                                        </el-checkbox-group>
                                    </div>
                                    <div class="sub_title">{{ $t('status') }}</div>
                                    <div class="edition_wrap">
                                        <el-checkbox-group v-model="filterStatus"
                                                           @change="setFilterFunc('st', filterStatus)">
                                            <el-checkbox :label="'auction'">{{ $t('auction') }}</el-checkbox>
                                            <el-checkbox :label="'license'">{{ $t('sale_license1') }}</el-checkbox>
                                            <el-checkbox :label="'pricetag'">{{ $t('fix_selling') }}</el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                    <div class="sub_title">{{ $t('edition') }}</div>
                                    <div class="edition_wrap">
                                        <el-checkbox-group v-model="filterEdition"
                                                           @change="setFilterFunc('ed', filterEdition)">
                                            <el-checkbox :label="1">{{ $t('edition') }}</el-checkbox>
                                            <el-checkbox :label="0"> {{ $t('single') }}</el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                    <div class="sub_title">{{ $t('type') }}</div>
                                    <div class="type_wrap">
                                        <el-checkbox-group v-model="filterType"
                                                           @change="setFilterFunc('ty', filterType)">
                                            <el-checkbox :label="'digital'">{{ $t('digital') }}</el-checkbox>
                                            <el-checkbox :label="'real'">{{ $t('real') }}</el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                    <div class="sub_title">{{ $t('price2') }}</div>
                                    <div class="price_wrap mb10">
                                        <el-input class="el_price_input" type="text"
                                                  oninput="value = value.replace(/[^0-9.]/g,'')"
                                                  v-model="priceInput[0]"></el-input>
                                        <span>
                            Mg ~
                            </span>
                                        <el-input class="el_price_input" type="text"
                                                  oninput="value = value.replace(/[^0-9.]/g,'')"
                                                  v-model="priceInput[1]"></el-input>
                                        <span>
                            Mg
                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper_bottom" @click="changeFilter()">
                            <button class="">{{ $t('apply2') }}</button>
                        </div>
                    </el-popover>
                </div>
                <div class="filter_sort_box">
                    <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                               popper-class="category_select"
                               @focus="showPopover=false"
                               :popper-append-to-body="false"
                               @change="sortChange(sortOption)">
                        <el-option :value="'last_update'" :label="$t('b_update')"></el-option>
                        <el-option :value="'im_real_hit'" :label="$t('b_enter_m')"></el-option>
                        <el-option :value="'im_like'" :label="$t('b_like')"></el-option>
                        <el-option :value="'priceDesc'" :label="$t('priceDesc')"></el-option>
                        <el-option :value="'priceAsc'" :label="$t('priceAsc')"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import util from "@/mixins/util";

const {filterConfig} = itemUtil
export default {
    name: "ItemFilterModalFilterLayout",
    mixins: [itemUtil],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInputOption: 'ina',
            sort: '',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            priceInput: ['', ''],
            showPopover: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
        if (!util.isEmpty(this.sortData)) {
            this.setterSortDataInData()
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setterSortDataInData() {
            this.sort = this.sortData.order;
            this.sortOption = this.sortData.key
            if (this.sortData.key === 'i_price') {
                if (this.sortData.order === 'desc') {
                    this.sortOption = `priceDesc`
                } else if (this.sortData.order === 'asc') {
                    this.sortOption = `priceAsc`
                }
            }
        },
        setData(key, value) {
            this[key] = value;
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.sort = 'asc'
                this.setSort('i_price', 'asc');
            } else if (data === 'priceDesc') {
                this.sort = 'desc'
                this.setSort('i_price', 'desc');
            } else {
                this.sort = 'desc'
                this.setSort(data, 'desc');
            }
        },
        changeFilter() {
            if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
                return
            }
            this.setFilterFunc('pr', this.priceInput);
            this.showPopover = false;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
